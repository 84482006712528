import { initializeApp } from "firebase/app";
import { Database, getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBEKJG7aCdNWHxBtyDfLU4QIeOkE83jpiY",
  authDomain: "link-shortener-5091e.firebaseapp.com",
  databaseURL: "https://link-shortener-5091e-default-rtdb.firebaseio.com",
  projectId: "link-shortener-5091e",
  storageBucket: "link-shortener-5091e.appspot.com",
  messagingSenderId: "1079822286115",
  appId: "1:1079822286115:web:8057a5509c3fe08b4a6096",
  measurementId: "G-3WT1TGDWFN"
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase();